import * as React from 'react'
import { memo } from 'react'

const SvgTag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18.801 18.8"
  >
    <path d="M8.275 17.419.83 9.975a1.555 1.555 0 0 1-.43-1.1l.168-6.846A1.5 1.5 0 0 1 2.027.569L8.873.402a1.558 1.558 0 0 1 1.1.431l7.443 7.468a3.211 3.211 0 0 1 .982 2.345 3.314 3.314 0 0 1-.982 2.346l-4.451 4.429a3.222 3.222 0 0 1-2.346.981 3.313 3.313 0 0 1-2.344-.983Zm-6.2-15.415a.076.076 0 0 0-.072.072l-.166 6.846c0 .024 0 .024.021.048l7.445 7.444a1.879 1.879 0 0 0 1.34.55 1.881 1.881 0 0 0 1.34-.55l4.43-4.428a1.887 1.887 0 0 0 .549-1.34 1.889 1.889 0 0 0-.549-1.341L8.968 1.861c-.023-.024-.023-.024-.047-.024Zm2.631 6.439a2.647 2.647 0 0 1 0-3.734 2.666 2.666 0 0 1 3.734 0 2.643 2.643 0 0 1 0 3.734 2.7 2.7 0 0 1-1.867.766 2.67 2.67 0 0 1-1.866-.767Zm1.031-2.7a1.228 1.228 0 0 0 0 1.7 1.228 1.228 0 0 0 1.7 0 1.21 1.21 0 0 0-.023-1.7 1.128 1.128 0 0 0-.84-.359 1.2 1.2 0 0 0-.836.354Z" />
  </svg>
)

const Memo = memo(SvgTag)
export default Memo
